import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Button, Dialog,  DialogContent,  DialogTitle, FormControl, IconButton, MenuItem, Popover, Select, TextField, Typography, createTheme, useMediaQuery } from "@mui/material";
import RemarkSVG from '../../../Assets/remarkIcon.svg';
import CustomTimePicker from "../../shared/common/CustomTimePicker";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import dayjs from 'dayjs';
import RequestService from '../../../Services/RequestService';
import CreateIcon from '@mui/icons-material/Create';
import RemarkDialog from './RemarkDialog';
import LeaveDialog from './LeaveDialog';

const theme = createTheme();

const UserAttendanceDialog = ({open, handleClose, data}) => {
   
    const [dialogData,setDialogData] = useState(data);
    const [dialogTableData,setDialogTableData] = useState({});
    const [updateArray, setUpdateArray] = useState([]);
    const [fetchedLeaveTypes, setFetchedLeaveTypes] = useState([]);
    const [dialogDataLoader,setDialogDataLoader] = useState(false);
    const [submitBulkLoading, setSubmitBulkLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [openRemark, setOpenRemark] = useState(false);
    const [remarkUpdating, setRemarkUpdating] = useState();
    const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
    const [halfDayLeave, setHalfDayLeave] = useState();

    const handleClickOpenRemark = (item) => {
        setRemarkUpdating(item);
      setOpenRemark(true);
    };
  
    const handleRemarkClose = (remark="", update=false) => {
        console.log("remark------------>",remark,"update--------------->",update);
        if(update){
            const dataIndex =  dialogTableData.findIndex((obj)=> {return obj._id === remarkUpdating._id});
            let newData = dialogTableData;
            newData[dataIndex].remark = remark;
            setDialogTableData(newData);
            if(updateArray && updateArray.length){
                let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newData[dataIndex]._id });
                console.log("find----index========>",findIndex);
                if(findIndex !== -1){
                    updateArray[findIndex].remark = remark;
                }else{
                    setUpdateArray((data)=>{return [ ...data, {_id:newData[dataIndex]?._id, remark: remark}]});
                }
            }else{
                setUpdateArray((data)=>{ return [ ...data, {_id:newData[dataIndex]?._id, remark: remark}]});
            }
        }
      setOpenRemark(false);
    };
  
    const handleLeaveClose = (leaveData, update=false) => {
        let newData = dialogTableData;
        if(update){
            let dataIndex = halfDayLeave.index;
            newData[dataIndex].leaveData = leaveData;
            setDialogTableData(newData);
            if(updateArray && updateArray.length){
                let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newData[dataIndex]._id });
                if(findIndex !== -1){
                    updateArray[findIndex].leaveData = leaveData;
                }else{
                    setUpdateArray((data)=>{return [ ...data, {_id:newData[dataIndex]?._id, type: "leave", leaveData: leaveData}]});
                }
            }else{
                setUpdateArray((data)=>{ return [ ...data, {_id:newData[dataIndex]?._id, type: "leave", leaveData: leaveData}]});
            }
        }else{
            newData[halfDayLeave.index].status = halfDayLeave.previousStatus;
            setDialogTableData(newData);
        }
      setOpenLeaveDialog(false);
    };

    useEffect(()=>{
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    },[screenSize]);

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    useEffect(()=>{
        getUserAttendance();
    },[]);
    
    const getUserAttendance = async() => {
        setDialogDataLoader(true);
        setDialogTableData([]);
        setUpdateArray([]);
        setSubmitBulkLoading(false);
        let response = await getSingleUserAttendace(data);
        if(response){
            setDialogTableData(response.map((res)=>{return {...res, remark: res.remark ? res.remark : ""}}));
            setDialogDataLoader(false);
        }
    }

    
    const getSingleUserAttendace = async (item) =>{
        try {
            return new Promise(async (resolve, reject)=>{
                let baseURL = "attendance/userAttendanceLeave/"+item?.UserDetails[0].email; 
                let queryParams = [];

                // queryParams.push(`userEmails=${encodeURIComponent(item?.UserDetails[0]?.email.toString())}`);
                if (queryParams.length > 0) {
                    baseURL += '?' + queryParams.join('&');
                }

                let response = await RequestService.fetchData("GET", baseURL);

                if(response.code === 200){
                    resolve(response.data);
                }else{
                    resolve([]);
                }
            
            })
        } catch (error) {
            console.log("error----------->",error);
        }
    }

    const handleStatusChange = (value, index, previousStatus) =>{
        let newData = dialogTableData;
        newData[index].status = value;
        if(value.includes("Leave")){
            setHalfDayLeave({...newData[index], halfDayLeave: value === "Half Day Leave Taken"? true : false, previousStatus: previousStatus, index: index});
            setOpenLeaveDialog(true);
            return
        }
        if(value.includes("WFH")){
            setDialogTableData(newData);
            let leaveData = {
                email: dialogData?.UserDetails[0]?.email,
                leave_type: "Work From Home",
                description: "",
                from_date: new Date(dayjs(new Date(newData[index].createdAt)).hour(10).minute(0).second(0).millisecond(0)),
                to_date: new Date(dayjs(new Date(newData[index].createdAt)).hour(10).minute(0).second(0).millisecond(0)),
                status: "1",
                day: "1"
            };
            if(updateArray && updateArray.length){
                let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newData[index]._id });
                if(findIndex !== -1){
                    updateArray[findIndex].leaveData = leaveData;
                }else{
                    setUpdateArray((data)=>{return [ ...data, {_id:newData[index]?._id, type: "leave", leaveData: leaveData}]});
                }
            }else{
                setUpdateArray((data)=>{ return [ ...data, {_id:newData[index]?._id, type: "leave", leaveData: leaveData}]});
            }
        }else if(updateArray && updateArray.length){
            let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newData[index]._id });
            if(findIndex !== -1){
                updateArray[findIndex].status = value === 'Present' ? "1": "0";
                updateArray[findIndex].check_out_address = newData[index].check_in_address;
            }else{
                setUpdateArray((data)=>{return [ ...data, {_id:newData[index]._id, check_out_address: newData[index].check_in_address, status: value === 'Present' ? "1": "0"}]});
            }
        }else{
            setUpdateArray((data)=>{ return [ ...data, {_id:newData[index]._id, check_out_address: newData[index].check_in_address, status: value === 'Present' ? "1": "0"}]});
        }
        setDialogTableData(newData);
    }

    const handleLocationChange = (value, index) =>{
        let newData = dialogTableData;
        newData[index].check_in_address = value;
        if(updateArray && updateArray.length){
            let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newData[index]._id });
            console.log("find----index========>",findIndex);
            if(findIndex !== -1){
                updateArray[findIndex].check_in_address = value;
                updateArray[findIndex].check_out_address = value;
            }else{
                setUpdateArray((data)=>{return [ ...data, {_id:newData[index]._id, check_in_address: value, check_out_address: value}]});
            }
        }else{
            setUpdateArray((data)=>{ return [ ...data, {_id:newData[index]._id, check_in_address: value, check_out_address: value}]});
        }
        setDialogTableData(newData);
    }

    const submitBulkUpdate = async () =>{
        console.log("onSubmit_array==============>",updateArray);
        if(updateArray && updateArray.length === 0){
            handleClose();
            return
        }
        setSubmitBulkLoading(true);
        let baseURL = "attendance/attendance_admin";
        
        let response = await RequestService.fetchData("POST", baseURL, updateArray);
        
        setSubmitBulkLoading(false);
        if(response.code === 200){
            handleClose(true);
        }else{
            handleClose();
            Alert("Please try after sometime.");
        }

    };

    const handleTimeChange = (index, newTime) => {
        const newTimes = [...dialogTableData];

        const date1 = dayjs(new Date(newTimes[index].createdAt)); // Date with no time
        const date2 = dayjs(new Date(newTime.$d)); // Date with time


        // Extract date, month, and year from the first date
        const year = date1.year();
        const month = date1.month() + 1; // Adding 1 because months in Day.js are zero-based
        const date = date1.date();

        // Extract hour, minutes, and seconds from the second date
        const hour = date2.hour();
        const minute = date2.minute();
        const second = date2.second();

        // Combine the extracted components into a new Day.js object
        const combinedDate = dayjs(`${year}-${month}-${date} ${hour}:${minute}:${second}`);

        newTimes[index].check_out_date = dayjs(combinedDate);
        if(updateArray && updateArray.length){
            let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newTimes[index]._id });
            console.log("find----index========>",findIndex);
            if(findIndex !== -1){
                updateArray[findIndex].check_out_date = dayjs(combinedDate).$d;
            }else{
                setUpdateArray((data)=>{return [ ...data, {_id:newTimes[index]._id, check_out_date: dayjs(combinedDate).$d}]});
            }
        }else{
            setUpdateArray((data)=>{ return [ ...data, {_id:newTimes[index]._id, check_out_date: dayjs(combinedDate).$d}]});
        }
        setDialogTableData(newTimes);
    };

    const handleInTimeChange = (index, newTime) => {
        const newTimes = [...dialogTableData];

        const date1 = dayjs(new Date(newTimes[index].createdAt)); // Date with no time
        const date2 = dayjs(new Date(newTime.$d)); // Date with time

        // Extract date, month, and year from the first date
        const year = date1.year();
        const month = date1.month() + 1; // Adding 1 because months in Day.js are zero-based
        const date = date1.date();

        // Extract hour, minutes, and seconds from the second date
        const hour = date2.hour();
        const minute = date2.minute();
        const second = date2.second();

        // Combine the extracted components into a new Day.js object
        const combinedDate = dayjs(`${year}-${month}-${date} ${hour}:${minute}:${second}`);

        newTimes[index].check_in_date = new Date(combinedDate);
        if(updateArray && updateArray.length){
            let findIndex = updateArray.findIndex((obj)=>{ return obj._id === newTimes[index]._id });
            console.log("find----index========>",findIndex);
            if(findIndex !== -1){
                updateArray[findIndex].check_in_date = dayjs(combinedDate);
            }else{
                setUpdateArray((data)=>{return [ ...data, {_id:newTimes[index]._id, check_in_date: dayjs(combinedDate)}]});
            }
        }else{
            setUpdateArray((data)=>{ return [ ...data, {_id:newTimes[index]._id, check_in_date: dayjs(combinedDate)}]});
        }
        setDialogTableData(newTimes);
    };

    //popover functionality

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  
    const handlePopOverClick = (event, index) => {
      setAnchorEl(event.currentTarget);
      setSelectedRowIndex(index);
    };
  
    const handlePopOverClose = () => {
      setAnchorEl(null);
      setSelectedRowIndex(null);
    };
  
    const openPopOver = Boolean(anchorEl);

  return (
    <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={'lg'}
        sx={{".MuiPaper-root":{borderRadius: '20px', padding: '10px'}}}
    >
        <DialogTitle id="responsive-dialog-title">
            <Box className="flex justify-between" sx={styles.headerSection}>
                {
                    dialogData && dialogData._id ? 
                        <Box className="flex gap-3 items-center">
                            <Avatar
                                key={"Avatar011"}
                                sx={{ width: 30, height: 30, cursor: 'pointer', marginLeft: '5px' }}
                                alt={dialogData?.UserDetails[0]?.displayName} // Assuming 'avatarAlt' is the property for alt text
                                src={dialogData?.UserDetails[0]?.imageURL} 
                            />
                            <Typography sx={styles.dialogUserName} >{dialogData?.UserDetails[0]?.displayName}</Typography>
                            <Typography sx={styles.dialogUserEmail}>{dialogData?.UserDetails[0]?.email}</Typography>
                        </Box>
                    :null
                }
                <Box>
                    <Button sx={styles.exportBTN} disabled={submitBulkLoading} onClick={()=>{submitBulkUpdate()}} >
                        {
                            submitBulkLoading ? 'Updating..' :'Bulk Save'
                        }
                    </Button>
                </Box>
            </Box>
            <Box className="absolute -right-0 top-0">
                <IconButton aria-label="close button" onClick={()=>{handleClose()}}>
                    <HighlightOffOutlinedIcon  sx={{color: '#221F4B'}} />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
        <div className='AttendanceTable' style={{width: screenSize.width<768 ? screenSize.width: 'auto', height: screenSize.width<768 ? screenSize.height-150 : screenSize.height-200,}}>
            <table className='w-full lookupTypeTable' style={{paddingRight: '2px'}}>
                <thead className='tableHeader'>
                        <th></th>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Check In time</th>
                        <th>Check Out Time</th>
                        <th>Status</th>
                        <th>Location</th>
                        <th > </th>
                </thead>
                <tbody className='tableBody' >
                    {
                        dialogDataLoader ? <tr> <td className='text-center' colSpan={12}> Loading... </td> </tr> :
                        dialogTableData && dialogTableData.length ?  
                        dialogTableData.map((item, index)=>{
                            return <tr className='tableBodyRow py-2 shadow-sm' key={index}>
                            <td></td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={styles.tableTextStyle}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{dayjs(item.createdAt).format('DD-MMM-YYYY')} </Typography> </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={{...styles.tableTextStyle, maxWidth: '100px'}}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{dayjs(item.createdAt).format('dddd')} </Typography> </td>
                            <td>
                                <CustomTimePicker
                                    key={index}
                                    label={`Time ${index + 1}`}
                                    index={index}
                                    value={item.check_in_date}
                                    handleTimeChange={handleInTimeChange}
                                /> 
                            </td>
                            <td>
                                
                                <CustomTimePicker
                                    key={index}
                                    label={`Time ${index + 1}`}
                                    index={index}
                                    value={item.check_out_date}
                                    handleTimeChange={handleTimeChange}
                                />
                            </td>
                            <td>
                                <FormControl sx={{ m: 1, minWidth: 120,}}  size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={item.status}
                                    onChange={(e)=>{ handleStatusChange(e.target.value, index, item.status); }}
                                    sx={styles.dialogSelectBox}
                                >
                                    <MenuItem value={'Not Checked-In'}>Not Checked-In</MenuItem>
                                    <MenuItem value={'Incomplete Check Out'}>Incomplete Check Out</MenuItem>
                                    <MenuItem value={'Late - Incomplete Check Out'}>Late - Incomplete Check Out</MenuItem>
                                    <MenuItem value={'Present'}>Present</MenuItem>
                                    <MenuItem value={'Late Arrival'}>Late Arrival</MenuItem>
                                    <MenuItem value={'Leave Taken'}>Leave Taken</MenuItem>
                                    <MenuItem value={'Half Day Leave Taken'}>Half Day Leave Taken</MenuItem>
                                    <MenuItem value={'Optional Leave Availed'}>Optional Leave Availed</MenuItem>
                                    <MenuItem value={'WFH'}>WFH</MenuItem>
                                    <MenuItem value={'WFH - Incomplete Check Out'}>WFH - Incomplete Check Out</MenuItem>
                                    <MenuItem value={'WFH - Not Checked In'}>WFH - Not Checked In</MenuItem>
                                    <MenuItem value={'WFH - Late Check In'}>WFH - Late Check In</MenuItem>
                                    <MenuItem value={'WFH - Late Check In + Incomplete Check Out'}>WFH - Late Check In + Incomplete Check Out</MenuItem>
                                    {/* <MenuItem value={'Absent'}>Absent</MenuItem> */}
                                </Select>
                                </FormControl>
                            </td>
                            <td>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={(item.check_in_address === "In-Office" || item.check_in_address === "Out-of-Office") ? item.check_in_address : "" }
                                        onChange={(e)=>{ handleLocationChange(e.target.value, index); }}
                                        sx={styles.dialogSelectBox}
                                    >
                                        <MenuItem value={'In-Office'}>In-Office</MenuItem>
                                        <MenuItem value={'Out-of-Office'}>Out-of-Office</MenuItem>
                                    </Select>
                                </FormControl>
                            </td>
                            <td className='actionCol'>
                                <div className='flex justify-end'>
                                    <button type='button' title='Remark' onClick={(e) => handlePopOverClick(e, index)} > 
                                        <img src={RemarkSVG} alt="Remark Icon" />
                                    </button>
                                    <Popover
                                        id={`${index}`} // Use unique id for each Popover
                                        open={openPopOver && selectedRowIndex === index} // Check if this Popover should be open
                                        anchorEl={anchorEl}
                                        onClose={handlePopOverClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        sx={{
                                            ".MuiPaper-root":{
                                                background: '#94B1CB',
                                                border: '1px solid #221F4B',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                padding: '10px',
                                            }
                                        }}
                                    >
                                        <Typography className='grow' sx={{ background: 'transparent', color: '#221F4B',  border: 'none', outline: 'none',maxWidth: '225px', }} >{item.remark || ""}</Typography>
                                        <IconButton onClick={()=>{handleClickOpenRemark(item)}} >
                                            <CreateIcon />
                                        </IconButton>
                                    </Popover>
                                </div>
                            </td>
                        </tr>
            
                        }) :
                        <tr >
                        <td colSpan={4} className='text-center pt-6 font-bold'> No Data Found </td>
                        </tr>
                    }
                    
                </tbody>
            </table>
        </div>
        {
            openRemark ? 
                <RemarkDialog openRemark={openRemark} handleRemarkClose={handleRemarkClose} data={remarkUpdating} />
            :null
        }
        {
            openLeaveDialog ? 
                <LeaveDialog openLeaveDialog={openLeaveDialog} handleLeaveClose={handleLeaveClose} data={{...halfDayLeave}} />
            :null
        }
        </DialogContent>
    </Dialog>
  );
};

export default UserAttendanceDialog;

const styles = {
    exportBTN: {
        px: '40px',
        py: '7px',
        backgroundColor: "#801F52",
        color: "#fff",
        "&:hover, &:focus, &:disabled":{
            backgroundColor: "#801F52",
            color: "#fff",
        }
    },
    tableTextStyle: {
        typography: {
          fontSize: "13px",
          maxWidth: "131px",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: { fontSize: "12px" },
        },
    },
    headerSection:{
        borderBottom: '1px solid #94B1CB',
        paddingBottom: '10px',
    },
    dialogUserName: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: "24.7px",
        color: '#FF914E',
    },
    dialogUserEmail: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: "17.29px",
        color: '#94B1CB',
    },
    dialogSelectBox: {
        ".MuiOutlinedInput-notchedOutline":{
            border: 'none',
            borderRight: '1px', 
            borderColor: '#94B1CB'
        },
        ".MuiInputBase-inputSizeSmall":{
            fontSize: '14px',
            fontWeight: 500,
            color: '#221F4B',
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "0px",
            width: '100%',
        },
        '& input': { // Targeting the input element of the Select component
            maxWidth: '100px' // Set your desired maxWidth here
        }
    },
};